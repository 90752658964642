<template>
  <section class="w-full">
    <b-card class="invoice-preview-card m-8">
      <div class="flex justify-between">
        <b-card class="border-0 float-right h-25">
          <h4 class="invoice-title border px-5 border-black py-2">
            <span class="text-primary"> شماره فاکتور : </span>
            {{ ManualPrintInfo.invoice_number }}
          </h4>
          <br />
          <div v-if="ManualPrintInfo.from_date">
            <div class="flex justify-around">
              <p class="font-weight-bold">تاریخ صدور :</p>
              <p class="">{{ ManualPrintInfo.from_jalali_date }}</p>
            </div>
          </div>
          <div v-else class="flex justify-around">
            <p class="font-weight-bold">تاریخ:</p>
            <p v-if="ManualPrintInfo.created_at" class="">
              {{ ManualPrintInfo.created_at.split(" ")[0] }}
            </p>
          </div>
        </b-card>
        <div>
          <h2 class="milad">صورتحساب فروش کالا یا خدمات</h2>
        </div>
        <div class="logo-wrapper">
          <img
            :src="require('@/assets/images/logo/logo-haio.png')"
            style="height:100px"
            alt="logo"
          />
        </div>
      </div>
      <!-- Header -->
      <vs-divider color=""
        ><span class="px-4 py-1 font-size-sm font-weight-bold"
          >فروشنده</span
        ></vs-divider
      >

      <b-card-body class="invoice-padding pb-0">
        <div class="d-flex justify-content-between flex-md-row flex-column">
          <div class="divTable" style="border: 2px solid #000">
            <div class="divTableBody">
              <div class="divTableRow">
                <div class="divTableCell">
                  <p class="card-text">
                    <span class="font-weight-bold">نام شرکت:</span>
                    شرکت ویرا ابر آینده جهان
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="font-weight-bold">شماره اقتصادی :</span>
                    411656646994
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="font-weight-bold">
                      شماره ثبت / شماره ملی :</span
                    >
                    14008780329
                  </p>
                </div>
              </div>
              <div class="divTableRow">
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="font-weight-bold"> آدرس :</span>
                    تهران پرند فاز ۲ بلوار شقایق -خ عطارد-مجتمع آریا-بلوک c-واحد
                    ۱۱۰
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="mx-1 font-weight-bold"> تلفن : </span>
                    <span dir="ltr" class=""> (۰۲۱) ۹۱۰۷۰۸۶۶</span>
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="mx-1 font-weight-bold"> کد پستی : </span>
                    <span dir="ltr" class=""> 3761341444</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
      <!-- Spacer -->
      <br />
      <vs-divider color=""
        ><span class="px-4 py-1 font-size-sm font-weight-bold"
          >خریدار</span
        ></vs-divider
      >

      <div class="p-4">
        <div
          v-if="!ManualPrintInfo.is_legal"
          class="divTable"
          style="border: 2px solid #000"
        >
          <div class="divTableBody">
            <div class="divTableRow">
              <div class="divTableCell">
                <p>
                  <span class="mx-1 font-weight-bold"> نام : </span>
                  <span dir="ltr" class="">
                    {{ ManualPrintInfo.user.name }}</span
                  >
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text mb-25">
                  <span class="mx-1 font-weight-bold">
                    شماره ثبت/ شماره ملی :
                  </span>
                  <span dir="ltr" class="">
                    {{ ManualPrintInfo.user.national_id }}</span
                  >
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text my-2">
                  <span class="my-2 font-weight-bold"> آدرس : </span>
                  <span dir="ltr" class="">{{
                    ManualPrintInfo.user.address
                  }}</span>
                </p>
              </div>
            </div>
            <div class="divTableRow">
              <div class="divTableCell">
                <p class="card-text my-2 mb-25">
                  <span class="mx-1 font-weight-bold"> کد پستی : </span>
                  <span dir="ltr" class="">{{
                    ManualPrintInfo.user.postal_code
                  }}</span>
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text mb-25">
                  <span class="mx-1 font-weight-bold">
                    شماره تلفن/نمابر :
                  </span>
                  <span dir="ltr" class="">
                    {{ ManualPrintInfo.user.phone }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- company name -->
        <div v-else class="divTable my-4" style="border: 2px solid #000">
          <div class="divTableBody">
            <div class="divTableRow">
              <div class="divTableCell">
                <p>
                  <span class="mx-1 font-weight-bold"> نام شرکت : </span>
                  <span dir="ltr" class="">
                    {{ ManualPrintInfo.user.company_name }}
                  </span>
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text mb-25">
                  <span class="mx-1 font-weight-bold">
                    شماره ثبت/ شماره ملی :
                  </span>
                  <span dir="ltr" class="">
                    {{ ManualPrintInfo.user.national_id }}</span
                  >
                </p>
              </div>
              <div class="divTableCell">
                <p v-show="ManualPrintInfo.is_legal" class="card-text mb-25">
                  <span class="my-1 font-weight-bold"> شماره اقتصادی : </span>
                  <span dir="ltr" class="">
                    {{ ManualPrintInfo.user.economic_code }}</span
                  >
                </p>
              </div>
            </div>
            <div class="divTableRow">
              <div class="divTableCell">
                <p class="card-text my-2">
                  <span class="my-2 font-weight-bold"> آدرس : </span>
                  <span dir="ltr" class="">{{
                    ManualPrintInfo.user.address
                  }}</span>
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text my-2 mb-25">
                  <span class="mx-1 font-weight-bold"> کد پستی : </span>
                  <span dir="ltr" class="">{{
                    ManualPrintInfo.user.postal_code
                  }}</span>
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text mb-25">
                  <span class="mx-1 font-weight-bold">
                    شماره تلفن/نمابر :
                  </span>
                  <span dir="ltr" class="">
                    {{ ManualPrintInfo.user.phone }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- company name -->
      <br />
      <vs-divider color=""></vs-divider>
      <br />
      <div
        id="userTable"
        class="flex flex-col table-bordered justify-center items-center"
      >
        <b-table
          class="w-full"
          :items="parse_description"
          :fields="fields"
        ></b-table>
      </div>

      <b-card-body class="pb-2 justify-center k justify-center">
        <b-card class="float-right border-black">
          <div class="mx-base">
            <table>
              <tbody>
                <tr>
                  <td class="pr-1">مبلغ:</td>
                  <td>
                    <span class="pr-1">{{ ManualPrintInfo.amount }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1">مالیات :</td>
                  <td>{{ ManualPrintInfo.tax }}</td>
                </tr>
                <tr>
                  <td class="pr-1">تخفیف :</td>
                  <td>{{ ManualPrintInfo.off }}</td>
                </tr>
                <tr>
                  <td class="pr-1">مالیات بر ارزش افزوده :</td>
                  <td>{{ ManualPrintInfo.tax_percent }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <vs-divider color="" class="my-50">
            <span class="py-1 font-size-sm font-weight-bold"
              >مبلغ پرداخت شده</span
            ></vs-divider
          >
          <div class="flex justify-center">
            <p dir="rtl" class="font-weight-bold">
              {{ ManualPrintInfo.final_amount | money }} ریال
            </p>
          </div>
        </b-card>
      </b-card-body>
      <hr class="invoice-spacing text-black border-black" />

      <!-- Note -->
      <b-card-body class="invoice-padding pt-0">
        <div>
          <span class="font-weight-bold">توضیحات: </span>
          <!-- <span>{{ JSON.parse(ManualPrintInfo.description) }}</span> -->
          <hr class="invoice-spacing text-black border-black mt-20" />
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      data: "",
      parse_description: [],
      test: { age: 15, name: "pedram" },
      invoicelist: "",
      invoice_id: this.$route.params.id,
      idPath: this.$route.params.id,
      fields: [
        { key: "description", label: " شرح کالا یا خدمات" },
        { key: "amount", label: " مبلغ (ریال)" }
      ],
      ManualPrintInfo: ""
    };
  },
  methods: {
    getinvoicelist() {
      this.$http
        .post("/admin/invoice/monthly/list", {
          monthly_invoice_id: this.$route.params.id
        })
        .then(res => {
          JSON.parse(res.data.params.data[0].description).forEach(el => {
            this.parse_description = [
              ...this.parse_description,
              { description: Object.keys(el)[0], amount: Object.values(el)[0] }
            ];
          });
          this.ManualPrintInfo = res.data.params.data[0];
          // console.log(this.ManualPrintInfo);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.getinvoicelist();
  }
};
</script>

<style>
.milad {
  color: black;
}

@page {
  size: A4;
  color: rgb(0, 0, 0);
}
.table-bordered {
  border: 2px solid black !important;
}
.table thead th {
  border: 1px solid black !important;
}
.table-bordered td {
  border: 1px solid black !important;
}
/* DivTable.com */
.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}
</style>
